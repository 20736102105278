<template>
  <!-- Search Men -->
  <div id="search-men">
    <AppContainer
      :useBackground="!isLoading ? true : false"
      :useShadow="!isLoading ? true : false"
    >
      <div class="search-men">
        <div
          style="
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            margin: auto;
            max-width: 1080px;
            z-index: 10;
          "
        >
          <div ref="header" class="header position-relative">
            <div class="btn-back">
              <ButtonBack />
            </div>
            <img
              class="logo"
              alt="logo-cast-search"
              src="~@/assets/image/logo-prego.png"
            />
            <b-icon
              @click="$refs.modalFilterCast.openModal()"
              icon="search"
              scale="1.5"
              v-if="$route.query.type != 2 && $route.query.type != 3"
              class="icon-search"
            />
          </div>
        </div>
        <NotifyHeader />
        <div id="content-scroll" class="content-scroll">
          <template v-if="isLoading">
            <AppLoading />
          </template>
          <template v-else>
            <div class="component" v-if="usersPopular.length > 0">
              <div class="row popular">
                <user-summary
                  v-for="(user, index) in usersPopular"
                  :key="index"
                  :propsUser="user"
                  :propsType="typeMulti"
                  :propsSex="1"
                  @update-like="changeLikeStatus($event)"
                />
              </div>
            </div>
            <div v-else class="empty-data">
              <img
                src="~@/assets/image/icon_profile/icon-empty-data.svg"
                alt=""
              />
              <div>現在データはありません。</div>
            </div>
          </template>
          <scroll-loader :loader-method="getList" :loader-disable="disable">
            <AppLoading v-if="page !== 1" />
            <p v-else></p>
          </scroll-loader>
        </div>
        <card-footer ref="footer" />
      </div>
    </AppContainer>
    <ModalFilterCast ref="modalFilterCast" :name="'検索'">
      <FilterMenPopup :type="type" @searchMen="searchMen($event)" />
    </ModalFilterCast>
  </div>
</template>

<script>
// @ is an alias to /src
import NotifyHeader from "@/components/NotifyHeader.vue";
import CardFooter from "@/components/CardFooter.vue";
import ButtonBack from "@/views/Common/ButtonBack.vue";
import UserSummary from "@/components/UserSummary.vue";
import { mapGetters } from "vuex";
import service from "@/utils/axios";
import { USER_URL } from "@/api/api";
import FilterMenPopup from "../../components/FilterMenPopup";
import ModalFilterCast from "@/views/Common/ModalFilterCast";
export default {
  name: "SearchMen",
  components: {
    "card-footer": CardFooter,
    ButtonBack,
    ModalFilterCast,
    FilterMenPopup,
    UserSummary,
    NotifyHeader
  },
  metaInfo() {
    return {
      title: "男性を検索",
      titleTemplate: "%s | PreGo"
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "common/isLoading"
    })
  },
  data() {
    return {
      typeMulti: { type: "multi" },
      usersPopular: [],
      type: 1,
      avg_score_lower: 60,
      avg_score_upper: 999,
      best_score_lower: 60,
      best_score_upper: 999,
      golf_area_ids: null,
      age_lower: 20,
      age_upper: 100,
      alcohol_ids: null,
      cigarette_ids: null,
      meeting_method: null,
      page: 1,
      perpage: 25,
      disable: false,
      query: {}
    };
  },
  async created() {
    // set perpage for device
    if (window.innerWidth < 992) {
      this.perpage = 8;
    }

    this.$store.dispatch("common/setIsLoading", true);
    this.query = {
      type: this.type,
      page: this.page,
      perpage: this.perpage,
      commit_type: "SET_LIST_USER",
      avg_score_lower: this.avg_score_lower,
      avg_score_upper: this.avg_score_upper,
      golf_area_ids: this.golf_area_ids,
      age_lower: this.age_lower,
      age_upper: this.age_upper,
      alcohol_ids: this.alcohol_ids,
      cigarette_ids: this.cigarette_ids,
      meeting_method: this.meeting_method
    };

    this.query = { ...this.query, ...this.$route.query };

    // get type from query route
    if (this.$route.query.type) {
      this.query.type = this.$route.query.type;
    }
    await this.requestData(this.query);
    this.$store.dispatch("common/setIsLoading", false);
  },
  methods: {
    // like and unlike user
    changeLikeStatus(data) {
      service({
        url: USER_URL + "/favorite",
        method: "POST",
        data: {
          user_id: data.userId,
          is_favorite: data.isFavorite === 1 ? 0 : 1
        }
      }).then(response => {
        const item = this.usersPopular.find(
          item => item.user_id === data.userId
        );
        if (response.data.success === true) {
          item.is_favorite = data.isFavorite === 1 ? 0 : 1;
        }
      });
    },
    // get list data
    async requestData(query) {
      await this.$store
        .dispatch("userApp/getListUser2", query)
        .then(response => {
          if (this.page === 1) {
            this.usersPopular = response.data.users;
          } else {
            this.usersPopular = [...this.usersPopular, ...response.data.users];
          }
          let total = response.data.total;
          if (total < this.page * this.perpage) {
            this.disable = true;
          } else {
            this.disable = false;
          }

          this.page++;
          if (this.page > 1) {
            this.query.page = this.page;
            this.query.commit_type = "SET_LIST_USER_MORE";
          }
        });
    },
    // for scroll load more
    getList() {
      if (this.page === 1) return false;
      this.requestData(this.query);
    },
    // action search
    async searchMen(event) {
      this.$refs["modalFilterCast"].closeModal();
      this.page = event.page;
      this.$store.dispatch("common/setIsLoading", true);
      await this.requestData(event);
      this.$store.dispatch("common/setIsLoading", false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/cast/searchMen.scss";
.col-6,
.col-5 {
  padding: 0;
}
.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 60px;
  padding-bottom: 100px;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   height: calc(100vh - 152px);
  // }
}
</style>
